import React from 'react'
import {Layout, InsurancesCarousel, WellnessBanner, MayInterestYouCards} from 'widgets'
import {BannerOnlineDoctor} from 'images'

export default () => {
    return(
        <Layout>
            <div className="online-doctor">
                <div
                    className="banner"
                    style={{
                        backgroundImage: `url(${BannerOnlineDoctor})`,
                    }}
                >

                </div>
                <div className="w-100 bg-purple">
                    <div className="text-center">
                        <h4 className="text-white font-weight-bold">
                            ¡Por la compra de 180 días te damos 30 días más!
                        </h4>
                    </div>
                </div>
                <WellnessBanner />
                <div className="insurance-container">
                    <div className="container p1">
                        <InsurancesCarousel />
                    </div>
                </div>
                <MayInterestYouCards />
            </div>
        </Layout>
    )
}